import React, { useState, useEffect } from "react";
import { db } from "../utils/firebase"; // Updated import
import { collection, addDoc } from "firebase/firestore"; // New Firestore imports
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import styles
import { doc, deleteDoc, getDocs } from "firebase/firestore";

function Admin() {
  const [title, setTitle] = useState("");
  const [description, setDiscription] = useState("")
//   const [content, setContent] = useState("");
  const [seoKeywords, setSeoKeywords] = useState("");
//   const [affiliateLink, setAffiliateLink] = useState("");
  const [editorHtml, setEditorHtml] = useState("");
  const [recipes, setRecipes] = useState([]);

  function handleChange(html) {
    setEditorHtml(html);
  }
//   async function saveToDatabase() {
//     try {
//       const docRef = await addDoc(collection(db, "recipes"), {
//         content: editorHtml,
//         // You can add other fields here.
//       });
//       console.log("Document written with ID: ", docRef.id);
//     } catch (error) {
//       console.error("Error adding document: ", error);
//     }
//   }
  useEffect(() => {
    async function fetchRecipes() {
      const querySnapshot = await getDocs(collection(db, "recipes"));
      const recipesArray = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setRecipes(recipesArray);
    }

    fetchRecipes();
  }, []);
  async function deleteRecipe(recipeId) {
    const recipeDoc = doc(db, "recipes", recipeId);
    await deleteDoc(recipeDoc);
    // Optional: Add logic here to update your component's state or UI.
    alert("Recipe deleted successfully!");
    window.location.reload();
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await addDoc(collection(db, "recipes"), {
        title: title,
        description: description,
        content: editorHtml,
        seoKeywords: seoKeywords,
        // affiliateLink: affiliateLink,
      });

      setTitle("");
      setDiscription("");
      setSeoKeywords("");
      setEditorHtml("");
    //   setAffiliateLink("");
      window.location.reload();
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };
  return (
    <div className="container mt-4">
      <h2 className="mb-4">Add New Recipe</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="title">Title</label>
          <input
            type="text"
            className="form-control"
            id="title"
            placeholder="Enter recipe title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">Description</label>
          <input
            type="text"
            className="form-control"
            id="description"
            placeholder="Enter recipe description"
            value={description}
            onChange={(e) => setDiscription(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="content">Content</label>
          <ReactQuill value={editorHtml} onChange={handleChange} />
        </div>
        {/* <div className="form-group">
          <label htmlFor="affiliateLink">Affiliate Link</label>
          <input
            type="text"
            className="form-control"
            id="affiliateLink"
            placeholder="Enter affiliate link"
            value={affiliateLink}
            onChange={(e) => setAffiliateLink(e.target.value)}
          />
        </div> */}
        <div className="form-group">
          <label htmlFor="seoKeywords">SEO Keywords</label>
          <input
            type="text"
            className="form-control"
            id="seoKeywords"
            placeholder="Enter SEO keywords separated by comma"
            value={seoKeywords}
            onChange={(e) => setSeoKeywords(e.target.value)}
          />
          <small className="form-text text-muted">
            Separate multiple keywords with a comma.
          </small>
        </div>
        <button type="submit" className="btn">
          Submit
        </button>
      </form>
      <div>
        {recipes &&
          recipes.map((recipe, index) => (
            <div key={index} className="card">
              <div className="card-title">{recipe.title}</div>
              <div className="card-content">
                <p>{recipe.id}</p>
                <p>{recipe.content}</p>
                {/* Add a button or link to delete the recipe */}
                <button onClick={() => deleteRecipe(recipe.id)} className="btn">Delete</button>
              </div>
            </div>
          ))}
      </div>
    </div>
  );

  // return (
  //     <div>
  //         <h1>Admin Dashboard</h1>
  //         <form onSubmit={handleSubmit}>
  //             <input
  //                 value={title}
  //                 onChange={e => setTitle(e.target.value)}
  //                 placeholder="Recipe Title"
  //             />
  //             <textarea
  //                 value={content}
  //                 onChange={e => setContent(e.target.value)}
  //                 placeholder="Recipe Content"
  //             />
  //             <input
  //                 value={seoKeywords}
  //                 onChange={e => setSeoKeywords(e.target.value)}
  //                 placeholder="SEO Keywords (comma-separated)"
  //             />
  //             <button type="submit">Add Recipe</button>
  //         </form>
  //     </div>
  // );
}

export default Admin;
