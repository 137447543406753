import React, { useState, useEffect } from "react";
import { db } from "../utils/firebase"; // Updated import
import { collection, getDocs } from "firebase/firestore"; // New Firestore imports
import { Link } from "react-router-dom";

function RecipeList() {
  const [recipes, setRecipes] = useState([]);

  useEffect(() => {
    async function fetchRecipes() {
      const querySnapshot = await getDocs(collection(db, "recipes"));

      setRecipes(
        querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
      );
    }

    fetchRecipes();
  }, []);

  return (
    <div>
      <h2 className="mb-4">All Recipes</h2>
      <div className="row">
        {recipes.map((recipe) => (
          <div className="col-md-4 mb-4" key={recipe.id}>
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">{recipe.title}</h5>
                <Link
                  to={`/recipes/${recipe.id}`}
                  className="btn"
                >
                  View Recipe
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RecipeList;
