import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
    return (
        <div className='text-center'>
            <h1>Welcome to Cuties Bistro</h1>
            <p className='lead'>Your culinary compass in the vast world of flavors! Dive into a treasure trove of handpicked recipes, expert tips, and mouthwatering tales from around the globe. Whether you're a seasoned chef or just starting to spark your culinary curiosity, our curated dishes promise a delightful journey for your taste buds. Grab your apron, gather your ingredients, and let's embark on a gastronomic adventure together!

</p>
            <Link to="/recipes">View All Recipes</Link>
        </div>
    );
}

export default Home;
