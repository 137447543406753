import { Outlet, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { auth } from "../utils/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";

// Update this import based on your Firebase initialization

function PrivateRoute() {
  let user = auth.currentUser;
  let navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/signin");
    }
  }, [user, navigate]);

  return <Outlet />;
}

const SignIn = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await signInWithEmailAndPassword(auth, email, password);
      setIsAuthenticated(true); // Redirect to admin dashboard or other protected route
    } catch (error) {
      setError(error.message);
    }
  };
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/admin"); // Redirect to the admin dashboard
    }
  }, [isAuthenticated, navigate]);

  return (
    <div className="signin-container">
      <h1 className="signin-title">Admin Sign In</h1>
      {error && <p>{error}</p>}
      <form onSubmit={handleSubmit} className="signin-form">
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="signin-input"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="signin-input"
        />
        <button type="submit" className="btn">
          Sign In
        </button>
      </form>
    </div>
  );
};

export { SignIn, PrivateRoute };
