import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import RecipeList from "./pages/RecipeList";
import RecipePage from "./pages/RecipePage";
import Admin from "./pages/Admin";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navbar, Nav } from "react-bootstrap";
import { SignIn, PrivateRoute } from "./pages/SignIn";

import "./App.css"; // for custom styles
import logo from "./assets/logo1.png";

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar collapseOnSelect expand="lg">
          <Navbar.Brand href="/">
            <img
              src={logo}
              width="120"
              height="70"
              className="d-inline-block align-top"
              alt="Cuties Bistro logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mx-auto">
              <Nav.Link href="/recipes">Recipes</Nav.Link>
              <Nav.Link href="/admin">Admin</Nav.Link>
              {/* Add other navigation links as needed */}
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        <div className="container mt-4">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/recipes" element={<RecipeList />} />
            <Route path="/recipes/:id" element={<RecipePage />} />
            <Route path="/admin" element={<PrivateRoute />}>
              <Route index element={<Admin />} />
            </Route>
            <Route path="/signin" element={<SignIn />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
