import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBrBlw1ojKH0Tg8cT-1Eh6CN3zEibf0hFU",
  authDomain: "cutiesbistro.firebaseapp.com",
  projectId: "cutiesbistro",
  storageBucket: "cutiesbistro.appspot.com",
  messagingSenderId: "58424960911",
  appId: "1:58424960911:web:b9af5e517106d4839bcdfa",
  measurementId: "G-EVS7CNB3B2",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };