import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db } from "../utils/firebase"; // Updated import
import { doc, getDoc } from "firebase/firestore"; // New Firestore imports
import { Helmet } from "react-helmet";

function RecipePage() {
  const { id } = useParams();
  const [recipe, setRecipe] = useState(null);

  useEffect(() => {
    async function fetchRecipe() {
      const recipeDoc = doc(db, "recipes", id);
      const docSnap = await getDoc(recipeDoc);

      if (docSnap.exists()) {
        setRecipe(docSnap.data());
      }
    }

    fetchRecipe();
  }, [id]);

  if (!recipe) return <div>Loading...</div>;
  console.log(recipe);

  return (
    <div>
      <Helmet>
        <title>
          {recipe?.title ? `${recipe.title} | Cuties Bistro` : "Cuties Bistro"}
        </title>
        <meta
          name="description"
          content={recipe?.description?.substring(0, 155) || ""}
          
        />
        <meta name="keywords" content={recipe?.seoKeywords} />

      </Helmet>
      <h1>{recipe.title}</h1>
      <p>{recipe.description}</p>
      <div dangerouslySetInnerHTML={{ __html: recipe.content || "" }}></div>
      {/* <h3>Check out this link for tools that we used: <a href={recipe.affiliateLink}>{recipe.affiliateLink}</a></h3> */}
      <p>
        <strong>Keywords:</strong> {recipe.seoKeywords}
      </p>
    </div>
  );
}

export default RecipePage;
